.modules-viewport{
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.modules-viewport-cover {
  background: -webkit-gradient(linear, left top, right top, from(#E65100), to(#FF9800));
  background: linear-gradient(to right, #E65100, #FF9800);
  position: relative; 
  height: 100%;
  opacity: 0.80;
  -moz-opacity: 80%;
  -webkit-opacity: 80%;
  overflow: hidden;
}

.modules-main {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -225px 0 0 -400px;
  width: 800px;
  height: 450px;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0,0,0,.08);

  .title span {
    font-size: 16px;
    text-transform: uppercase;
    display: block;
  }
  
  .title {
    text-align: center;
    line-height: 40px;
    margin-bottom: 14px;
  }

  .widget {
    border-radius: 16px !important;
    color: #fff;
    cursor: pointer;
    
    h1,h4 {
      color: #fff;
    }
  }
}
