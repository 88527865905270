.app-layout-logo {
  height: 32px;
  /*background: rgba(255, 255, 255, 0.2);*/
  margin: 16px;
  display: flex;
  justify-content: center;

  .logoImg {
    width: 32px;
    /*margin-right: 8px;*/
  }
}

.app-layout .app-layout-background {
  background: #fff;
}

.ant-form-item-label > label {
  font-size: 18px!important;
}

.ant-list-item-meta-description {
  font-size: 16px!important;
  color:rgba(0, 0, 0,0.7)!important
 }

 .ant-list-item-meta-title {
  font-size:18px!important

 }