.login-viewport{
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-viewport-cover {
  background: -webkit-gradient(linear, left top, right top, from(#E65100), to(#FF9800));
  background: linear-gradient(to right, #E65100, #FF9800);
  position: relative; 
  width: 100%;
  height: 100%;
  opacity: 0.80;
  -moz-opacity: 80%;
  -webkit-opacity: 80%;
  overflow: hidden;
}

.login-main {
  background-color: #fff;
  position: absolute;
  top: 40%;
  left: 50%;
  margin: -160px 0 0 -230px;
  width: 450px;
  height: 450px;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0,0,0,.08);

  p {
    color:#333333;
    text-align: center;
    margin-top: 16px;

    span {
      &:first-child {
        margin-right: 16px;
      }
    }
  }

  .logoWrapper span {
    font-size: 16px;
    text-transform: uppercase;
    display: block;
  }
  
  .logoWrapper {
    text-align: center;
    height: 200px;
    line-height: 40px;
    cursor: pointer;
    margin-bottom: 14px;
  
    .logoImg {
      width: 150px;
      /*margin-right: 8px;*/
    }
  }
  
  .loginErrorMessage,
  .recoverErrorMessage {
    font-size: 14px;
    color: red;
  }

  .recoverSuccessMessage {
    font-size: 14px;
    color: green;
  }

}
